import {
  Card,
  CardBody,
  Text
} from '@chakra-ui/react'

function HiddenCard({ job }) {
  return (
    <Card mb={2} className="job-card" style={{ height: 'auto' }} key={job.id}>
      <CardBody>
        <Text fontSize='xl'>{job.title}</Text>
        <Text fontSize="xs" color="gray">{job.hidden ? 'Hidden by' : 'Applied by'} {job.user_name}</Text>
      </CardBody>
    </Card>
  )
}

export default HiddenCard
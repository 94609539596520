import { Stack, Checkbox, FormLabel } from '@chakra-ui/react'

function HoursPerWeek({
  hoursPerWeek, setHoursPerWeek,
  paymentVerified, setPaymentVerified
}) {
  return (
    <Stack>
      <FormLabel>Hours per week</FormLabel>
      <Checkbox
        isChecked={hoursPerWeek.less}
        onChange={e => setHoursPerWeek({ ...hoursPerWeek, less: !hoursPerWeek.less })}
      >Less than 30 hrs/week</Checkbox>
      <Checkbox
        isChecked={hoursPerWeek.more}
        onChange={e => setHoursPerWeek({ ...hoursPerWeek, more: !hoursPerWeek.more })}
      >More than 30 hrs/week</Checkbox>
      <Checkbox
        mt={2}
        isChecked={paymentVerified}
        onChange={e => setPaymentVerified(!paymentVerified)}
      ><FormLabel>Payment verified</FormLabel></Checkbox>
    </Stack>
  )
}

export default HoursPerWeek
import { useEffect, useState } from "react"
import { Flex, Select, Text } from '@chakra-ui/react'
import axios from 'axios';

import { useApi } from "../utils/context";
import UnfoldedCard from "../components/Cards/UnfoldedCard";
import Pagination from "../components/Pagination";
import { Spinner } from "react-bootstrap";


function HiddenJobs() {
  const [filteredJobs, setFilteredJobs] = useState(null);
  const [managers, setManagers] = useState([]);
  const [activeManager, setActiveManager] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const { apiData, setApiData } = useApi();

  const paginate = pageNumber => setCurrentPage(pageNumber);

  async function fetchJobs() {
    try {
      setIsLoading(true);
      const res = await axios.get(`https://test22.aestar.com.ua/upw/jobs?offset=${(currentPage - 1) * apiData.itemsPerPage}&count=${apiData.itemsPerPage}&status=hidden`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = res.data;
      if (data.data.length) {
        setFilteredJobs(data.data);
        setApiData({
          ...apiData,
          jobs: data.data,
          totalCount: data.totalCount
        })
        setManagers([...new Set(data.data.map(job => job.user_name))]);
      } else {
        console.log('Jobs list is empty');
      }
      setIsLoading(false);
    } catch (e) {
      console.error('Oops, something went wrong while getting jobs:', e);
    }
  }


  useEffect(() => {
    fetchJobs();
  }, [])

  useEffect(() => {
    if (apiData.jobs) {
      setFilteredJobs(
        activeManager
          ? apiData.jobs.filter(job => job.user_name === activeManager)
          : apiData.jobs
      );
    }
  }, [activeManager, apiData.jobs]);

  return (
    <section>
      {isLoading ? <Spinner mt={4} alignSelf={'center'} /> : apiData.jobs.length ? (
        <>
          <Flex alignItems='center' justifyContent='center' mb={2}>
            <Text me={2}>Filter by:</Text>
            <Select
              onChange={e => setActiveManager(e.target.value)}
              placeholder='All'
              w='25%'>
              {managers && managers.map(manager =>
                <option key={manager} value={manager}>{manager}</option>
              )}
            </Select>
          </Flex>
          {filteredJobs.map(job => (
            <div key={job.id}>
              <UnfoldedCard statused={true} job={job} setJobs={setFilteredJobs} />
            </div>
          ))}
          {apiData.jobs.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(apiData.jobs.length / apiData.itemsPerPage)}
              onPageChange={paginate}
            />
          )}
        </>
      ) : (
        <Text fontSize='xl' align='center'>Jobs are not found</Text>
      )}
    </section>
  )
}

export default HiddenJobs
import { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import { Button, Spinner, Text } from '@chakra-ui/react';


const statsDescription = {
  totalApplied: {
    name: 'К-сть прийнятих вакансій',
    description: 'Загальна кількість прийнятих вакансій станом за минулий тиджень.',
    compare: true
  },
  totalHidden: {
    name: 'К-сть відхилених вакансій',
    description: 'Загальна кількість прийнятих вакансій станом за минулий тиджень.',
    compare: true
  },
  lastWorkedDateTime: {
    name: 'Дата останньої роботи',
    description: "Остання позначка часу між відпрацьованими годинами та останньою датою платежу з останнього контракту.",
    dateFunc: true
  },
  lastApplicationDate: {
    name: 'Дата останнього звернення',
    description: "Дата, коли користувач востаннє подавався на роботу.",
    dateFunc: true
  },
  totalHourlyJobs: {
    name: 'Всього погодинних робіт',
    description: " Загальна кількість погодинних робіт.",
    compare: true
  },
  totalHourlyJobsRecent: {
    name: 'Всього останніх погодинних вакансій',
    description: "Загальна кількість погодинних робіт за останні 180 днів",
    compare: true
  },
  totalFixedJobs: {
    name: 'Всього фіксованих робочих місць',
    description: "Всього робіт з фіксованою ціною.",
    compare: true
  },
  totalFixedJobsRecent: {
    name: 'Загальна кількість останніх постійних робіт',
    description: "Загальна кількість робіт за фіксованою ціною, виконаних за останні 180 днів.",
    compare: true
  },
  activeInterviews: {
    name: "Активні інтерв'ю",
    description: "Загальна кількість незавершених співбесід.",
    compare: true
  },
  currentContracts: {
    name: 'Поточні договори',
    description: "Загальна кількість активних контрактів.",
    compare: true
  },
  jobSuccessScore: {
    name: 'Оцінка успішності роботи',
    description: "Показник успішності роботи розраховується раз на два тижні.",
    compare: true
  },
  topRatedStatus: {
    name: 'Статус з найвищим рейтингом',
    description: "Статус з найвищим рейтингом."
  },
  topRatedPlusStatus: {
    name: 'Статус із найвищим плюс рейтингом',
    description: "Статус із найвищим плюс рейтингом."
  },
  totalHoursActual: {
    name: 'Загальна кількість годин',
    description: "Загальна кількість годин розраховується шляхом поділення загальної суми на погодинну ставку.",
    compare: true
  },
  totalHours: {
    name: 'Загальна кількість оплачених годин',
    description: "Загальна кількість оплачених годин. Підсумок виставлених годин із загальною сумою >= 1 дол.",
    compare: true
  },
  totalHoursRecent: {
    name: 'Загальна кількість годин за останній час',
    description: "Загальна кількість відпрацьованих годин за останні 180 днів.",
    compare: true
  },
  totalFeedback: {
    name: 'Загальний відгук',
    description: "Загальна кількість отриманих відгуків.",
    compare: true
  },
  totalFeedbackRecent: {
    name: 'Загальна кількість останніх відгуків',
    description: "Загальна оцінка відгуків, отримана за останні 180 днів.",
    compare: true
  },
  billedContracts: {
    name: 'Виставлені рахунки контракти',
    description: "Кількість контрактів із загальними зборами >= $1.",
    compare: true
  },
  billedContractsRecent: {
    name: ' Нещодавні виставлені контракти',
    description: "Кількість контрактів із загальними зборами >= $1 за останні 180 днів.",
    compare: true
  },
  adjustedFeedbackScore: {
    name: 'Скоригована оцінка відгуку',
    description: "Скоригований рейтинг фрілансера. Оцінка у 5 зірок, яку платформа надає кожному фрілансеру на основі відгуків, отриманих за кожен виконаний контракт.",
    compare: true
  },
  lastActivityDateTime: {
    name: 'Дата і час останньої активності',
    description: "Позначка часу останньої активності, зафіксована платформою з таких дій: останнє подання заявки на роботу, останній зданий іспит, остання дата роботи, остання дата найму, остання дата платежу.",
    dateFunc: true
  },
  topRatedStatusDatetime: {
    name: 'Найвищий рейтинг дати і часу',
    description: "Остання позначка часу, коли фрілансеру було надано статус із найвищим рейтингом.",
    dateFunc: true
  },
  qualityScore: {
    name: 'Показник якості',
    compare: true
  },
  totalHoursRounded: {
    name: 'Загальна кількість годин округлена',
    compare: true
  },
  totalCharge360: {
    name: 'Загальна плата',
    description: "Загальна сума стягнень за останні 360 днів/",
    compare: true
  },
  totalCharge360NoAgency: {
    name: 'Загальна плата без агентства',
    description: "Загальна сума витрат за останні 360 днів, за винятком агентських контрактів.",
    compare: true
  },
  adjustedScore360: {
    name: 'Скоригований бал',
    description: "Оцінка відгуків, розрахована з використанням контрактів, укладених за останні 360 днів.",
    compare: true
  },
  longTermClients: {
    name: 'Довгострокові клієнти',
    compare: true
  },
  suspensions: {
    name: 'Призупинення',
    description: "Загальна кількість призупинень.",
    compare: true
  },
  suspensions360: {
    name: 'Призупинення 360',
    description: "Загальна кількість призупинень, зафіксована за останні 360 днів.",
    compare: true
  },
  suspensions90limited: {
    name: 'Блокування',
    description: "Останні блокування облікового запису.",
    compare: true
  },
  topLevelJobCategoryApplied90Days: {
    name: 'Категорія роботи найвищого рівня',
    description: "Основна категорія роботи (категорія роботи рівня 1), на яку фрілансер звертався за останні 90 днів.",
  },
  proposalsCount90Days: {
    name: 'Пропозиції',
    description: "Кількість заявок на роботу, поданих фрілансером за останні 90 днів.",
    compare: true
  },
  medianProposalsForTheTopLevelCategory365: {
    name: 'Середні пропозиції для категорії вищого рівня',
    description: "Медіана кількості заявок на роботу в основній категорії до першого найму за останні 365 днів.",
    compare: true
  },
  fitProposalsViewRatio90Days: {
    name: 'Співвідношення переглядів пропозицій',
    description: "Співвідношення кількості переглянутих відповідних заявок на роботу до кількості поданих заявок на роботу за останні 90 днів.",
    compare: true
  },
  hiddenProposalsViewedRatio90Days: {
    name: 'Співвідношення переглядів прихованих пропозицій',
    description: "Співвідношення кількості переглянутих прихованих заявок на роботу до кількості поданих заявок на роботу за останні 90 днів.",
    compare: true
  },
  totalProposalsViewedRatio90Days: {
    name: 'Загальний коефіцієнт переглядів пропозицій',
    description: "Співвідношення кількості переглянутих заявок на роботу до кількості поданих заявок на роботу за останні 90 днів.",
    compare: true
  },
  proposalInterviewedRation90Days: {
    name: "Співвідношення пропозиція - інтерв'ю",
    description: "Співвідношення кількості заявок на роботу, за якими клієнт провів інтерв'ю, до кількості поданих заявок на роботу за останні 90 днів.",
    compare: true
  },
  proposalsHiredRatio90Days: {
    name: 'Співвідношення прийнятих - поданих заявок',
    description: "Співвідношення кількості прийнятих на роботу до кількості поданих заявок на роботу за останні 90 днів.",
    compare: true
  },

  totalInvites90Days: {
    name: 'Кількість запрошень',
    description: "Загальна кількість запрошень, отриманих за останні 90 днів.",
    compare: true
  },
  totalInviteResponses90Days: {
    name: 'Кількість відповідей на запрошення',
    description: "Загальна кількість відповідей на запрошення, отримані за останні 90 днів.",
    compare: true
  },
  inviteResponsesPerDay90Days: {
    name: 'Кількості запрошень з відповіддю',
    description: "Підрахунок кількості запрошень, на які підрядник відповів протягом дня за останні 90 днів.",
    compare: true
  },
  totalCharge365NoPending: {
    name: 'Загальна плата',
    description: "Прибуток за 12 місяців на основі прибутку за останні 365 днів, включаючи сьогодні",
    compare: true
  },
  totalCharge90: {
    name: 'Загальний заряд',
    description: "Заробіток за 3 місяці",
    compare: true
  },
  weeksEligibleWithin16wks: {
    name: 'Найвищі тижні',
    description: "Тижні з найвищим рейтингом за останні 16 тижнів.",
    compare: true
  }
}

function StatisticsByManager() {
  const [data, setData] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState(moment().startOf('isoWeek').format('LL'))
  const [dates, setDates] = useState([]);

  async function fetchStats() {
    try {
      setIsLoading(true);
      const res = await axios.get(`https://test22.aestar.com.ua/upw/user/stats?date=${moment(currentDate).format('YYYY-MM-DD HH:mm:ss.SSS')}&prevDate=${moment(currentDate).subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss.SSS')}`);
      if (res.data.status === 'success') {
        setData(res.data.data);
        setPrevData(res.data.prevData);
        const dates = res.data.dates.map(date => moment(date).format('LL'));
        setDates(dates);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    fetchStats();
  }, [currentDate])

  const getColor = (title, user) => {
    if (statsDescription[title]?.compare && prevData[user.user_name]) {
      let diff = user.data[title] - prevData[user.user_name][title];
      if (typeof user.data[title] === 'string') {
        diff = parseFloat(user.data[title].replace(/[^\d.-]/g, '')) - parseFloat(prevData[user.user_name][title].replace(/[^\d.-]/g, ''));
      }
      if (diff > 0) {
        return { color: 'green', diff };
      } else if (diff < 0) {
        return { color: 'red', diff };
      }
    }
    return { color: 'inherit' };
  };

  if (isLoading) return <Spinner mt={4} alignSelf={'center'} />;
  if (!data.length) return <Text fontSize='xl' align='center'>Data are not found</Text>;
  return (
    <div className='mx-3'>
      {dates.length &&
        <div>
          {dates.map(date =>
            <Button
              key={date}
              onClick={e => setCurrentDate(date)}
              colorScheme={date === currentDate ? 'green' : 'gray'}
            >{date}</Button>
          )}
        </div>
      }
      <Table variant='striped'>
        <thead>
          <tr>
            <th style={{ paddingLeft: '100px', paddingRight: '100px' }}>Description</th>
            <th>Title</th>
            {
              data.map(item =>
                <th key={item.user_id}>{item.user_name}</th>
              )
            }
          </tr>
        </thead>
        <tbody>
          {Object.keys(data[0].data).map((title, index) => (
            <tr key={index}>
              <td>{statsDescription[title]?.description}</td>
              <td>{statsDescription[title]?.name ?? title}</td>
              {data.map((user, idx) => {
                const { color, diff } = getColor(title, user);

                return (<td style={{ color }} key={idx}>{
                  user.data[title] !== null ?
                    statsDescription[title]?.dateFunc ? moment(new Date(user.data[title])).format('LL') :
                      statsDescription[title]?.compare && prevData[user.user_name] && color !== 'inherit' ? `${user.data[title]} (${diff})` :
                        (typeof user.data[title] === 'object' ? '-' : user.data[title]) : '-'
                }</td>)
              }
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default StatisticsByManager
import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { CiEdit } from "react-icons/ci";
import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  useToast,
  List,
  ListItem
} from '@chakra-ui/react'
import { useApi } from '../../utils/context';


const CoverLetter = ({
  job, statused, isCoverOpen,
  setIsCoverOpen, updateJobState
}) => {
  const [coverLetter, setCoverLetter] = useState(job.coverLetter ?? '');
  const [isEditable, setIsEditable] = useState(false);
  const [jobQuestions, setJobQuestions] = useState([]);

  const { apiData, setApiData, currUser } = useApi();

  const toast = useToast()

  useEffect(() => {
    if (isCoverOpen && !statused) {
      fetchCoverLetter();
      fetchJobQuestions();
    }
  }, [isCoverOpen])

  async function fetchCoverLetter() {
    setIsCoverOpen(true);
    setIsEditable(true);
    try {
      const res = await axios.post(`https://test22.aestar.com.ua/upw/cover-letter/generate`, {
        content: job.description
      });
      if (res.data.status === 'success') {
        setCoverLetter(res.data.data);
      }
    } catch (e) {
      console.error('Oops, something went wrong while generating a cover letter:', e);
    }
  }
  async function fetchJobQuestions() {
    try {
      const res = await axios.get(`https://test22.aestar.com.ua/upw/jobs/questions?jobId=${job.id}`);
      if (res.data.status === 'success') {
        setJobQuestions(res.data.questions);
      }
    } catch (e) {
      console.error('Oops, something went wrong while fetching job questions:', e);
    }
  }
  async function updateCoverLetter() {
    try {
      const res = await axios.post(`https://test22.aestar.com.ua/upw/cover-letter/update`, {
        coverLetter,
        editedBy: currUser.name,
        jobId: job.id
      });
      if (res.data.status === 'success') {
        const updatedJobs = apiData.jobs.map(item => {
          if (item.id === job.id) {
            item.coverLetter = coverLetter;
          }
          return item;
        })
        setApiData({
          ...apiData,
          jobs: updatedJobs
        });
        toast({
          title: 'Success',
          description: "Cover letter is successfully updated.",
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      cancelCoverEditing();
    } catch (e) {
      console.error('Oops, something went wrong while updating a job:', e);
    }
  }
  async function applyJob() {
    setIsCoverOpen(false);
    try {
      const res = await axios.post(`https://test22.aestar.com.ua/upw/jobs/apply`, {
        userId: currUser.id,
        userName: currUser.name,
        jobId: job.id,
        coverLetter
      });
      if (res.data.status === 'success') {
        updateJobState(false, true);
        setJobQuestions([]);
        toast({
          title: 'Success',
          description: "Cover letter is successfully sent.",
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (e) {
      console.error('Oops, something went wrong while hidding a job:', e);
    }
  }

  function cancelCoverEditing() {
    setIsEditable(false);
    setCoverLetter(job.coverLetter);
  }

  return (
    <Modal
      isOpen={isCoverOpen}
      onClose={e => {
        setIsCoverOpen(false);
        setJobQuestions([]);
        cancelCoverEditing();
      }}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cover Letter</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {jobQuestions.length ?
            <>
              <Text as='b'>Questions list:</Text>
              <List mb={3}>
                {jobQuestions.map((question, id) =>
                  <ListItem key={id}>{question}</ListItem>
                )}
              </List>
            </> : ''}
          {job.coverEditedBy && <Text fontSize="xs" mb={3} color="gray">Last modified by {job.coverEditedBy} at {moment(new Date(job.coverEditedAt), "YYYY-MM-DDTHH:MM:SS±HHMM").fromNow()}</Text>}
          {
            isEditable ?
              <Textarea
                required
                height='400'
                value={coverLetter}
                onChange={e => setCoverLetter(e.target.value)}
                placeholder='Cover Letter' />
              : job.coverLetter
          }
        </ModalBody>
        <ModalFooter>
          {!statused ?
            <Button isDisabled={!coverLetter} onClick={applyJob} colorScheme='green'>Submit</Button>
            : currUser?.role === 'admin' && (
              isEditable ?
                <>
                  <Button onClick={cancelCoverEditing} mr={3} colorScheme='red'>Cancel</Button>
                  <Button isDisabled={!coverLetter} onClick={updateCoverLetter} colorScheme='green'>Save</Button>
                </>
                :
                <Button onClick={e => setIsEditable(true)} background='transparent'>
                  <CiEdit fontSize='26' />
                </Button>
            )
          }
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CoverLetter
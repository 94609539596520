import { MdDelete, MdAddCircleOutline, MdInfo } from "react-icons/md";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Input,
  Spinner,
} from '@chakra-ui/react'
import { useState } from "react";
import axios from "axios";

function KeyWordsHandler({ setIsDeleting, setKeyWordsList }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keyWordInput, setKeyWordInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  async function createKeyWord() {
    setIsLoading(true);
    try {
      const res = await axios.post(`https://test22.aestar.com.ua/upw/key-words/save`, { keyWordInput });
      if (res.data.status) {
        setMessage(res.data.message);
        if (res.data.status === 'success') {
          setKeyWordsList(prev => [...prev, { id: res.data.id, name: keyWordInput }]);
          setKeyWordInput('');
        }
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error('Oops, something went wrong while saving a key word:', e);
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        m={1}
        fontSize='2xl'
        color="gray"
        variant='ghost'
        _hover={{ bg: '#aeb9af', color: '#fff' }}
        onClick={() => setIsDeleting(prev => !prev)}
      ><MdDelete /></Button>
      <Button
        me={3}
        fontSize='2xl'
        color="blue"
        variant='ghost'
        _hover={{ bg: '#63bf63', color: '#fff' }}
        onClick={e => setIsModalOpen(true)}
      ><MdAddCircleOutline /></Button>
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip width={100} id="button-tooltip">
            <div>Щоб додати ключове слово, натисніть кнопку +.</div>
            <div>Щоб видалити ключові слова, натисніть <MdDelete style={{ display: 'inline' }} />. Для автоматичного видалення використовуйте хрестики у переліку.</div>
            <div>Другий клік на <MdDelete style={{ display: 'inline' }} /> вимкне функцію видалення.</div>
          </Tooltip>
        }
      >
        <Button
          _hover={{ bg: 'transparent' }}
          bg='transparent'>
          <MdInfo color="gray" fontSize='24px' /></Button>
      </OverlayTrigger>

      <Modal isOpen={isModalOpen} onClose={e => {
        setIsModalOpen(false);
        setMessage('');
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create new key word</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <Input
                value={keyWordInput}
                onChange={e => setKeyWordInput(e.target.value)}
                placeholder='unity'
                type='text' />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            {isLoading ? <Spinner mt={4} alignSelf={'center'} /> : <p style={{ margin: '7px' }}>{message}</p>}
            <Button onClick={createKeyWord} colorScheme='green'>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default KeyWordsHandler
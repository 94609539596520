import { Stack, Checkbox, InputGroup, InputLeftElement, Input, FormLabel } from '@chakra-ui/react'

const Price = ({
  price, hourlyPrice, fixedPrice,
  setPrice, setHourlyPrice, setFixedPrice
}) => {
  return (
    <Stack>
      <FormLabel>Job type</FormLabel>
      <Checkbox
        isChecked={price.hourly}
        onChange={e => setPrice({ ...price, hourly: !price.hourly })}
      >Hourly</Checkbox>

      <Stack pl={5} direction='row' align={'center'}>
        <Checkbox
          isChecked={price.hourlyDetails}
          onChange={e => setPrice({ ...price, hourly: !price.hourlyDetails ?? true, hourlyDetails: !price.hourlyDetails })}
        ></Checkbox>
        <InputGroup size='sm' w={75}>
          <InputLeftElement pointerEvents='none' color='gray.600' fontSize='1.2em'>
            $
          </InputLeftElement>
          <Input
            value={hourlyPrice.min}
            onChange={e => setHourlyPrice({ ...hourlyPrice, min: e.target.value })}
            placeholder='Min' />
        </InputGroup><p style={{ margin: 0 }}> / hr </p>
        <InputGroup size='sm' w={75}>
          <InputLeftElement pointerEvents='none' color='gray.600' fontSize='1.2em'>
            $
          </InputLeftElement>
          <Input
            value={hourlyPrice.max}
            onChange={e => setHourlyPrice({ ...hourlyPrice, max: e.target.value })}
            placeholder='Max' />
        </InputGroup><p style={{ margin: 0 }}> / hr </p>
      </Stack>

      <Checkbox
        isChecked={price.fixed}
        onChange={e => setPrice({ ...price, fixed: !price.fixed })}
      >Fixed-Price</Checkbox>

      <Stack pl={5} direction='row'>
        <Checkbox
          isChecked={price.fixedDetails}
          onChange={e => setPrice({ ...price, fixed: !price.fixedDetails ?? true, fixedDetails: !price.fixedDetails })}
        ></Checkbox>
        <InputGroup size='sm' w={75}>
          <InputLeftElement pointerEvents='none' color='gray.600' fontSize='1.2em'>
            $
          </InputLeftElement>
          <Input
            value={fixedPrice.min}
            onChange={e => setFixedPrice({ ...fixedPrice, min: e.target.value })}
            placeholder='Min' />
        </InputGroup>
        <InputGroup size='sm' w={75}>
          <InputLeftElement pointerEvents='none' color='gray.600' fontSize='1.2em'>
            $
          </InputLeftElement>
          <Input
            value={fixedPrice.max}
            onChange={e => setFixedPrice({ ...fixedPrice, max: e.target.value })}
            placeholder='Max' />
        </InputGroup>
      </Stack>
    </Stack>
  )
}

export default Price
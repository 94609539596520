
import { useState, createContext, useContext } from "react";
export const ApiContext = createContext()

function ApiContextProvider({ children }) {
  const [apiData, setApiData] = useState({
    itemsPerPage: 20,
    jobs: [],
  });
  const [currUser, setCurrUser] = useState(null);

  return (
    <ApiContext.Provider value={{
      apiData, setApiData,
      currUser, setCurrUser
    }}>
      {children}
    </ApiContext.Provider>
  );
}

export const useApi = () => useContext(ApiContext);

export default ApiContextProvider
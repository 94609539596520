import HiddenCard from './Cards/HiddenCard';
import UnfoldedCard from "./Cards/UnfoldedCard";


function JobCard({ job, showKeyWords }) {

  return (
    <>{
      job.user_id ? <HiddenCard job={job} /> : <UnfoldedCard job={job} showKeyWords={showKeyWords} />
    }</>
  )
}

export default JobCard
import { useEffect } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import {
  Flex,
  Spacer,
  Text,
  Image,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react';
import { FaAngleDown } from "react-icons/fa6";
import { useApi } from '../utils/context';

function Header() {
  const { apiData, currUser, setCurrUser } = useApi();

  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  const currentLocation = splitLocation[splitLocation.length - 1];

  const [searchParams, setSearchParams] = useSearchParams();

  async function fetchUser() {
    try {
      let token = searchParams.get('token');
      if (token) {
        localStorage.setItem('token', token);
      } else {
        token = localStorage.getItem('token');
      }

      if (!token && currentLocation !== 'login') {
        window.location.href = 'https://test22.aestar.com.ua/login';
      } else {
        const res = await axios.post('https://test22.aestar.com.ua/upw/user', { token });
        setCurrUser(res.data.user);
      }
    } catch (e) {
      console.error('Oops, something went wrong while getting user:', e);
    }
  }

  useEffect(() => {
    fetchUser();
  }, [])

  return (
    <header style={{ display: 'flex', alignItems: 'center' }}>
      {apiData.totalCount && <Text m={5} fontSize="xs" color="gray">Total found: {apiData.totalCount}</Text>}
      <Spacer />
      <nav style={{ marginRight: '15px' }}>
        <ul className='header-list'>
          <li>
            <Link
              className={`header-menu-btn ${currentLocation === '' ? 'active' : ''}`}
              to="/upwork/">Total</Link>
          </li>
          <li>
            <Link
              className={`header-menu-btn ${currentLocation === 'applied-jobs' ? 'active' : ''}`}
              to="/upwork/applied-jobs">Accepted</Link>
          </li>
          <li>
            <Link
              className={`header-menu-btn ${currentLocation === 'hidden-jobs' ? 'active' : ''}`}
              to="/upwork/hidden-jobs">Rejected</Link>
          </li>
          <li>
            <Menu>
              <MenuButton
                className={`header-menu-btn ${currentLocation === 'by-manager' || currentLocation === 'general' ? 'active' : ''}`}
                as={Button} rightIcon={<FaAngleDown />}>
                Statistics
              </MenuButton>
              <MenuList>
                <MenuItem>
                  <Link style={{ width: '100%' }} to="/upwork/statistics/general">General</Link>
                </MenuItem>
                <MenuItem>
                  <Link style={{ width: '100%' }} to="/upwork/statistics/by-manager">By Manager</Link>
                </MenuItem>
                <MenuItem>
                  <Link style={{ width: '100%' }} to="/upwork/statistics/by-skills">By Skills</Link>
                </MenuItem>
              </MenuList>
            </Menu>
          </li>
        </ul>
      </nav>
      {currUser &&
        <Flex me={5}>
          <Text color="gray" pe={3}>{currUser.name}</Text>
          <Image
            borderRadius='full'
            boxSize='25px'
            src={currUser.photoUrl}
            alt={currUser.name}
          />
        </Flex>
      }
    </header>
  )
}

export default Header
import { useEffect, useState } from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    const pageLimit = 5;
    let startPage = Math.max(currentPage - Math.floor(pageLimit / 2), 1);
    let endPage = Math.min(startPage + pageLimit - 1, totalPages);

    if (endPage - startPage < pageLimit - 1) {
      startPage = Math.max(endPage - pageLimit + 1, 1);
    }

    const numbers = [];
    for (let i = startPage; i <= endPage; i++) {
      numbers.push(i);
    }
    setPageNumbers(numbers);
  }, [currentPage, totalPages]);

  return (
    <div className="pagination">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>

      {currentPage > 3 && totalPages > 5 && (
        <>
          <button onClick={() => onPageChange(1)}>1</button>
          {currentPage > 4 && <span>...</span>}
        </>
      )}

      {pageNumbers.map(number => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={number === currentPage ? 'active' : ''}
        >
          {number}
        </button>
      ))}

      {currentPage < totalPages - 2 && totalPages > 5 && (
        <>
          {currentPage < totalPages - 3 && <span>...</span>}
          <button onClick={() => onPageChange(totalPages)}>{totalPages}</button>
        </>
      )}

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;

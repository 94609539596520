import { useEffect, useState } from "react"
import axios from 'axios'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Table from 'react-bootstrap/Table';
import { Button, Flex, Spinner, Text } from '@chakra-ui/react';
import "react-datepicker/dist/react-datepicker.css";

function General() {
  const [data, setData] = useState(null);
  const [categories, setCategories] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleDatePicker = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };


  async function fetchStats() {
    try {
      setIsLoading(true);
      const res = await axios.get(`https://test22.aestar.com.ua/upw/stats/general?startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
      if (res.data.status === 'success' && res.data.result) {
        const result = res.data.result;
        setData(result);

        const keyWords = [
          ...new Set([
            ...Object.keys(result.total.keyWords || {}),
            ...Object.keys(result.applied.keyWords || {}),
            ...Object.keys(result.hidden.keyWords || {}),
          ])
        ];

        const tags = [
          ...new Set([
            ...Object.keys(result.total.tags || {}),
            ...Object.keys(result.applied.tags || {}),
            ...Object.keys(result.hidden.tags || {}),
          ])
        ];

        setCategories({ keyWords, tags });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    fetchStats();
  }, [])

  if (isLoading) return <Spinner mt={4} alignSelf={'center'} />;
  if (!data || !categories) return <Text fontSize='xl' align='center'>Data are not found</Text>;
  return (
    <div>
      <Flex alignItems='center'>
        <DatePicker
          selected={startDate}
          onChange={handleDatePicker}
          startDate={startDate}
          endDate={endDate}
          selectsRange
        />
        <Button
          ml={2}
          color="green"
          variant='outline'
          colorScheme='green'
          _hover={{ bg: '#008000', color: '#fff' }}
          onClick={fetchStats}
        >Submit</Button>
      </Flex>
      <Table variant='striped'>
        <thead>
          <tr>
            <th></th>
            <th>Total</th>
            <th>Applied</th>
            <th>Hidden</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TOTAL</td>
            <td>{data.total.total || 0}</td>
            <td>{data.applied.applied || 0}</td>
            <td>{data.hidden.hidden || 0}</td>
          </tr>
          <tr><td className="stats-divider" colSpan={4}>KEY WORDS:</td></tr>
          {categories.keyWords.map((category) =>
            <tr key={category}>
              <td>{category}</td>
              <td>{data.total.keyWords[category] || '-'}</td>
              <td>{data.applied.keyWords[category] || '-'}</td>
              <td>{data.hidden.keyWords[category] || '-'}</td>
            </tr>
          )}
          <tr><td className="stats-divider" colSpan={4}>TAGS:</td></tr>
          {categories.tags.map((category) =>
            <tr key={category}>
              <td>{category}</td>
              <td>{data.total.tags[category] || '-'}</td>
              <td>{data.applied.tags[category] || '-'}</td>
              <td>{data.hidden.tags[category] || '-'}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div >
  )
}

export default General
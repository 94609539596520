import { Routes, Route, Outlet } from 'react-router-dom';

import Header from './components/Header';

import AppliedJobs from './pages/AppliedJobs';
import HiddenJobs from './pages/HiddenJobs';
import Home from './pages/Home';
import Login from './pages/Login';
import StatisticsByManager from './pages/Statistics/ByManager';
import StatisticsGeneral from './pages/Statistics/General';
import StatisticsSkills from './pages/Statistics/BySkills';

function App() {

  return (
    <div style={{ maxWidth: '1220px', width: '100%', margin: '0 auto' }}>
      <Header />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route index path="/upwork/" element={<Home />} />
        {/* <Route index path="/upwork/key-words" element={<Home searchType={'keyWords'} />} /> */}
        <Route path="/upwork/applied-jobs" element={<AppliedJobs />} />
        <Route path="/upwork/hidden-jobs" element={<HiddenJobs />} />
        <Route path="/upwork/statistics/general" element={<StatisticsGeneral />} />
        <Route path="/upwork/statistics/by-manager" element={<StatisticsByManager />} />
        <Route path="/upwork/statistics/by-skills" element={<StatisticsSkills />} />
      </Routes>
      <Outlet />
    </div>
  );
}

export default App;

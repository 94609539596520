import { Stack, Checkbox, FormLabel } from '@chakra-ui/react'

function ProjectLength({ projectLength, setProjectLength }) {
  return (
    <Stack>
      <FormLabel>Project length</FormLabel>
      <Checkbox
        isChecked={projectLength.oneMonth}
        onChange={e => setProjectLength({ ...projectLength, oneMonth: !projectLength.oneMonth })}
      >Less than one month</Checkbox>
      <Checkbox
        isChecked={projectLength.threeMonths}
        onChange={e => setProjectLength({ ...projectLength, threeMonths: !projectLength.threeMonths })}
      >1 to 3 months</Checkbox>
      <Checkbox
        isChecked={projectLength.sixMonths}
        onChange={e => setProjectLength({ ...projectLength, sixMonths: !projectLength.sixMonths })}
      >3 to 6 months</Checkbox>
      <Checkbox
        isChecked={projectLength.longTerm}
        onChange={e => setProjectLength({ ...projectLength, longTerm: !projectLength.longTerm })}
      >More than 6 months</Checkbox>
    </Stack>
  )
}

export default ProjectLength
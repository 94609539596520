import moment from 'moment';
import axios from 'axios';
import {
  Button,
  Card,
  CardBody,
  Flex,
  Spacer,
  Spinner,
  Text
} from '@chakra-ui/react'
import { useApi } from "../../utils/context";
import { useEffect, useState } from 'react';
import CoverLetter from '../Modals/CoverLetter';


function UnfoldedCard({ job, statused }) {
  const [keyWords, setKeyWords] = useState(null);
  const [isCoverOpen, setIsCoverOpen] = useState(false);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(false);

  function checkVerificationStatus(status) {
    switch (status) {
      case 'VERIFIED':
        return 'verified'
      case 'NOT_VERIFIED':
        return 'not verified'
      case 'ACTIVE_BUT_NOT_VERIFIED':
        return 'active but not verified'
      case 'VERIFICATION_IN_PROGRESS':
        return 'in progress'
      case 'UNKNOWN':
        return 'unknown'
      default:
        return 'not verified'
    }
  }

  const { apiData, setApiData, currUser } = useApi();

  useEffect(() => {
    if (!apiData.keyWords) return;
    setKeyWords(apiData.keyWords?.reduce((acc, key) => {
      acc[key.id] = key.name;
      return acc;
    }, {}));
  }, [apiData.keyWords])

  function updateJobState(hidden, applied) {
    const updatedJobs = apiData.jobs.filter(item => {
      if (item.id == job.id) {
        if (!hidden && !applied) {
          return;
        }
        item.hidden = hidden;
        item.applied = applied;
        item.user_id = (hidden || applied) ? currUser.id : null;
        item.user_name = (hidden || applied) ? currUser.name : null;
      }
      return item;
    })
    setApiData({
      ...apiData,
      jobs: updatedJobs
    });
  }

  async function hideJob() {
    try {
      const res = await axios.post(`https://test22.aestar.com.ua/upw/jobs/hide`, {
        userId: currUser.id,
        userName: currUser.name,
        jobId: job.id
      });
      if (res.data.status === 'success') {
        updateJobState(true, false);
      }
    } catch (e) {
      console.error('Oops, something went wrong while hidding a job:', e);
    }
  }

  async function revokeJob() {
    try {
      const res = await axios.get(`https://test22.aestar.com.ua/upw/jobs/revoke?job=${job.id}`);
      if (res.data.status === 'success') {
        updateJobState(false, false);
      }
    } catch (e) {
      console.error('Oops, something went wrong while revoking a job:', e);
    }
  }

  async function refreshJob() {
    setIsRefreshed(true);
    setIsLoading(true);
    try {
      const res = await axios.post(`https://test22.aestar.com.ua/upw/jobs/refresh`, {
        jobId: job.id,
        jobTitle: job.title,
        location: job.client.location.city
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = res.data.data;
      if (res.data.status === 'success' && data) {
        const updatedJobs = apiData.jobs.filter(item => {
          if (item.id == job.id) {
            item = { ...item, ...data }
          }
          return item;
        })
        setApiData({
          ...apiData,
          jobs: updatedJobs
        });
      }
      setMessage(res.data.message);
    } catch (e) {
      console.error('Oops, something went wrong while refreshing a job:', e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Card mb={2} className="job-card" key={job.id}>
      <CardBody p={3}>
        <Flex>
          <div style={{ width: '65%' }}>
            <Text fontSize="xs" color="gray">Posted {moment(new Date(job.publishedDateTime), "YYYY-MM-DDTHH:MM:SS±HHMM").fromNow()}</Text>
            {statused && job.applied ?
              <a target="_blank" href={`https://www.upwork.com/jobs/` + job.ciphertext}><Text fontSize='xl'>{job.title}</Text></a>
              : <Text fontSize='xl'>{job.title}</Text>
            }
            {job.amount.displayValue !== '0.0' ?
              <Text
                fontSize="sm"
                color="gray"
                style={{
                  fontWeight: 500
                }}
              >Fixed price - Est. budget: ${job.amount.displayValue}
              </Text>
              : <Text fontSize="sm" color="gray">Hourly{job.hourlyBudgetMin ? `: $${job.hourlyBudgetMin.displayValue} - $${job.hourlyBudgetMax.displayValue} ` : ' '}
                - Est. time: {job.durationLabel}, {job.engagement}</Text>
            }
            <Text mt={2} className="job-description scrollable">{job.description}</Text>
          </div>
          <Spacer />
          <div style={{ width: '25%' }}>
            <Text>Country: {job.client.location.country}</Text>
            <Text>Feedback: {job.client.totalFeedback}</Text>
            <Text>Total reviews: {job.client.totalReviews}</Text>
            <Text>Total hires: {job.client.totalHires}</Text>
            <Text>Total posted jobs: {job.client.totalPostedJobs}</Text>
            <Text>Payment method {checkVerificationStatus(job.client.verificationStatus)}</Text>
            <Text>Total spent: {job.client.totalSpent?.displayValue ?? 0}</Text>
          </div>
        </Flex>
        {job.skills.length ?
          <ul className="search-list scrollable">
            <label>Tags: </label>
            {job.skills.map(skill => (
              <li style={{ display: 'inline' }} key={skill.prettyName}>
                <p className='search-elem-item'>{skill.prettyName}</p></li>
            ))}
          </ul> : ''
        }
        {keyWords && job.keyIds.length ?
          <ul className="search-list scrollable">
            <label>Key words: </label>
            {job.keyIds.map(keyId => (
              <li style={{ display: 'inline' }} key={keyId + job.job_id}>
                <p className='search-elem-item'>{keyWords[keyId]}</p></li>
            ))}
          </ul> : ''
        }
        {statused ?
          <Flex mt={5} alignItems='center'>
            <Text mr={3} fontSize="md" color={job.hidden ? 'red' : 'green'}>{job.hidden ? 'Hidden by' : 'Applied by'} {job.user_name}</Text>
            <Button onClick={e => setIsCoverOpen(true)} size={'sm'} me={3} color="black">Cover letter</Button>
            <Spacer />
            {isRefreshed ?
              (isLoading ? <Spinner mt={4} /> : <p style={{ margin: '7px' }}>{message}</p>)
              :
              (!job.hidden && (moment().diff(moment(job.refreshed_at), 'hours') >= 1)) &&
              <Button onClick={refreshJob} me={3} color="green">Refresh</Button>
            }
            <Button onClick={revokeJob} me={3} color="blue">Revoke</Button>
          </Flex>
          :
          <div style={{ marginTop: '20px' }}>
            <Button onClick={() => setIsCoverOpen(true)} me={3} color="green">Apply</Button>
            <Button onClick={hideJob} size="sm" color="red">Hide</Button>
          </div>
        }
        <CoverLetter
          job={job}
          statused={statused}
          isCoverOpen={isCoverOpen}
          setIsCoverOpen={setIsCoverOpen}
          updateJobState={updateJobState} />
      </CardBody>
    </Card >
  )
}

export default UnfoldedCard
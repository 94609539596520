import { useEffect } from 'react';
import { Box, Text, Spinner } from '@chakra-ui/react';
import axios from 'axios';

function Login() {
  async function startOAuthFlow() {
    try {
      const response = await axios.get('https://test22.aestar.com.ua/upw/auth');
      console.log('Redirecting to Upwork for authorization...');

      window.location.href = response.data.authUrl;
    } catch (error) {
      console.error('Error initiating OAuth flow:', error);
    }
  }

  useEffect(() => {
    startOAuthFlow();
  }, [])

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <Text fontSize="xl" mb={4}>Redirecting to Upwork for authorization...</Text>
      <Spinner size="xl" />
    </Box>
  )
}

export default Login
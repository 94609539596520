import { useState } from 'react'
import axios from 'axios';
import { Flex, Spacer, Button } from '@chakra-ui/react'

import Price from './Price';
import HoursPerWeek from './HoursPerWeek';
import ProjectLength from './ProjectLength';
import KeyWordsList from '../KeyWords/List';
import { useApi } from '../../utils/context';

const projectDuration = {
  oneMonth: 'Less than 1 month',
  threeMonths: '1 to 3 months',
  sixMonths: '3 to 6 months',
  longTerm: 'More than 6 months',
}

const weekEngagement = {
  less: 'Less than 30 hrs/week',
  more: '30+ hrs/week'
}

function Filter({
  setIsLoading,
  count,
  offset,
  keyWordsList,
  setKeyWordsList,
  currentKeyWords,
  setCurrentKeyWords,
  setFilters
}) {
  const [paymentVerified, setPaymentVerified] = useState(false);

  const [price, setPrice] = useState({
    hourly: false,
    hourlyDetails: false,
    fixed: false,
    fixedDetails: false
  });
  const [hourlyPrice, setHourlyPrice] = useState({ min: '', max: '' });
  const [fixedPrice, setFixedPrice] = useState({ min: '', max: '' });
  const [projectLength, setProjectLength] = useState({
    oneMonth: false,
    threeMonths: false,
    sixMonths: false,
    longTerm: false
  });
  const [hoursPerWeek, setHoursPerWeek] = useState({
    less: false,
    more: false
  })

  const { apiData, setApiData } = useApi();

  function resetFiltering() {
    setFilters(null);
    setPrice({
      hourly: false,
      hourlyDetails: false,
      fixed: false,
      fixedDetails: false
    });
    setHourlyPrice({ min: '', max: '' });
    setFixedPrice({ min: '', max: '' });
    setProjectLength({
      oneMonth: false,
      threeMonths: false,
      sixMonths: false,
      longTerm: false
    });
    setHoursPerWeek({
      less: false,
      more: false
    })
  }

  async function sumbitFiltering() {
    setIsLoading(true);
    const filters = {
      keyWords: currentKeyWords.map(key => key.id),
      priceType: {
        hourly: price.hourly,
        hourlyDetails: price.hourlyDetails,
        hourlyPrice,
        fixed: price.fixed,
        fixedDetails: price.fixedDetails,
        fixedPrice
      },
      projectDuration: Object.keys(projectLength)
        .filter(key => projectLength[key])
        .map(key => projectDuration[key]),
      hoursPerWeek: Object.keys(hoursPerWeek)
        .filter(key => hoursPerWeek[key])
        .map(key => weekEngagement[key]),
      paymentVerified
    }

    setFilters(filters);

    try {
      const res = await axios.post(`https://test22.aestar.com.ua/upw/jobs/filter?offset=${offset}&count=${count}`, { filters });
      if (res.data.status === 'success') {
        setApiData({
          ...apiData,
          jobs: res.data.data,
          totalCount: res.data.totalCount
        })
      }
      setIsLoading(false);
    } catch (e) {
      console.error('Oops, something went wrong while hidding a job:', e);
    }
  }

  return (
    <div style={{ background: 'rgb(53 206 33 / 7%)', padding: '20px' }}>
      <KeyWordsList
        keyWordsList={keyWordsList}
        setKeyWordsList={setKeyWordsList}
        currentKeyWords={currentKeyWords}
        setCurrentKeyWords={setCurrentKeyWords} />

      <Flex mt={5} mx={6}>
        <Price
          price={price}
          hourlyPrice={hourlyPrice}
          fixedPrice={fixedPrice}
          setPrice={setPrice}
          setHourlyPrice={setHourlyPrice}
          setFixedPrice={setFixedPrice} />
        <Spacer />
        <ProjectLength
          projectLength={projectLength}
          setProjectLength={setProjectLength} />
        <Spacer />
        <HoursPerWeek
          hoursPerWeek={hoursPerWeek}
          setHoursPerWeek={setHoursPerWeek}
          paymentVerified={paymentVerified}
          setPaymentVerified={setPaymentVerified} />
      </Flex>
      <div style={{ textAlign: 'end' }}>
        <Button
          onClick={resetFiltering}
          colorScheme='gray'
          variant='outline'
          color="gray"
          me={3}>Reset</Button>
        <Button
          me={3}
          color="green"
          variant='outline'
          colorScheme='green'
          _hover={{ bg: '#008000', color: '#fff' }}
          onClick={sumbitFiltering}
        >Apply</Button>
      </div>
    </div>
  )
}

export default Filter
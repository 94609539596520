import { Fragment, useEffect, useState } from "react"
import axios from 'axios'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Table from 'react-bootstrap/Table';
import { Button, Flex, Spinner, Text } from '@chakra-ui/react';
import "react-datepicker/dist/react-datepicker.css";

const BySkill = () => {
  const [startDate, setStartDate] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'));
  const [endDate, setEndDate] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'));
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [uniqueDates, setUniqueDates] = useState([]);

  const handleDatePicker = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  async function fetchStats() {
    try {
      setIsLoading(true);
      const res = await axios.get(`https://test22.aestar.com.ua/upw/stats/skills?startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
      if (res.data.status === 'success' && res.data.data) {
        const result = res.data.data;
        setData(result);
        setUniqueDates([...new Set(result.flatMap(skill => skill.data.map(item => item.date)))]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    fetchStats();
  }, [])


  if (isLoading) return <Spinner mt={4} alignSelf={'center'} />;
  if (!data || !uniqueDates) return <Text fontSize='xl' align='center'>Data are not found</Text>;
  return (
    <div>
      <Flex alignItems='center' mb={4}>
        <DatePicker
          selected={startDate}
          onChange={handleDatePicker}
          startDate={startDate}
          endDate={endDate}
          selectsRange
        />
        <Button
          ml={2}
          color="green"
          variant='outline'
          colorScheme='green'
          _hover={{ bg: '#008000', color: '#fff' }}
          onClick={fetchStats}
        >Submit</Button>
      </Flex>
      <Table variant='striped' style={{ width: 'auto' }}>
        <thead>
          <tr>
            <th className='border-right' style={{ minWidth: '100px' }}></th>
            {uniqueDates.map(date => (
              <th className='border-right' key={date} colSpan="2" style={{ minWidth: '150px', textAlign: 'center' }}>{date}</th>
            ))}
          </tr>
          <tr>
            <th className='border-right' >Skill</th>
            {uniqueDates.map(date => (
              <Fragment key={date}>
                <th key={`${date}-jobs`}>Jobs</th>
                <th className='border-right' key={`${date}-apps`}>Apps</th>
              </Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((skill, index) => (
            <tr key={index}>
              <td className='border-right' >{skill.skill}</td>
              {uniqueDates.map(date => {
                const record = skill.data.find(item => item.date === date) || { totalJobs: 0, totalApplicants: 0 };
                return (
                  <Fragment key={`${skill.skill}-${date}`}>
                    <td key={`${date}-jobs-data`}>{record.totalJobs}</td>
                    <td className='border-right' key={`${date}-apps-data`}>{record.totalApplicants}</td>
                  </Fragment>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default BySkill
import { useState } from "react";
import axios from "axios";
import { Button, Badge } from "@chakra-ui/react";
import { TiDelete } from "react-icons/ti";
import { useApi } from "../../utils/context";
import KeyWordsHandler from './Handler';
import { IoCloseCircleOutline } from "react-icons/io5";
import { GoPlusCircle } from "react-icons/go";

function KeyWordsList({
  keyWordsList,
  setKeyWordsList,
  currentKeyWords,
  setCurrentKeyWords
}) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isKeyWordsListOpen, setIsKeyWordsListOpen] = useState(false);
  const { currUser } = useApi();

  async function deleteKeyWord(id) {
    try {
      const res = await axios.post(`https://test22.aestar.com.ua/upw/key-words/delete`, { keyWordId: id });
      if (res.data.status === 'success') {
        setKeyWordsList(prev => prev.filter(key => key.id !== id));
      }
    } catch (e) {
      console.error('Oops, something went wrong while saving a key word:', e);
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className="key-words-handler-block" style={{
        height: isKeyWordsListOpen ? 'auto' : '150px',
        overflowY: 'hidden',
        borderBottom: '1px solid #b0b0b0',
        borderRadius: '5px',
      }}>
        {currUser?.role === 'admin' &&
          <KeyWordsHandler
            setKeyWordsList={setKeyWordsList}
            setIsDeleting={setIsDeleting}
          />}
        {
          keyWordsList.map(word =>
            <Button
              pos='relative'
              mr={1} mb={1}
              key={word.id}
              onClick={e => {
                const index = currentKeyWords.map(key => key.id).indexOf(word.id);
                if (index === -1) {
                  setCurrentKeyWords([...currentKeyWords, word]);
                } else {
                  setCurrentKeyWords(currentKeyWords.filter(currWord => currWord.id !== word.id));
                }
              }}
              colorScheme={currentKeyWords.includes(word) ? 'green' : 'gray'}
            >{word.name}
              {isDeleting &&
                <Badge
                  bg='transparent'
                  pos='absolute'
                  top='-8px'
                  right='-10px'>
                  <TiDelete onClick={() => deleteKeyWord(word.id)} fontSize='26px' />
                </Badge>
              }
            </Button>
          )
        }
      </div>
      <button
        onClick={() => setIsKeyWordsListOpen(!isKeyWordsListOpen)}
        className="key-words-handler-open-btn">{
          isKeyWordsListOpen ? <IoCloseCircleOutline /> : <GoPlusCircle />
        }</button>
    </div>
  )
}

export default KeyWordsList
import { useEffect, useState } from "react"
import axios from 'axios';

import { useApi } from "../utils/context";
import JobCard from "../components/JobCard";
import Pagination from "../components/Pagination";
import Filter from "../components/Filters";
import { Text, Spinner } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";


function Home() {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('Jobs not found');
  const [currentKeyWords, setCurrentKeyWords] = useState([]);
  const [keyWordsList, setKeyWordsList] = useState([]);
  const [filters, setFilters] = useState(null);

  const { apiData, setApiData } = useApi();
  const [searchParams, setSearchParams] = useSearchParams();

  const paginate = pageNumber => setCurrentPage(pageNumber);

  async function fetchJobs() {
    try {
      setIsLoading(true);
      const offset = (currentPage - 1) * apiData.itemsPerPage;
      const url = `https://test22.aestar.com.ua/upw/jobs${filters ? '/filter' : ''}?offset=${offset}&count=${apiData.itemsPerPage}`;

      const res = filters
        ? await axios.post(url, { filters })
        : await axios.get(url);

      const data = res.data;
      if (data.data.length) {
        setApiData(prev => ({
          ...prev,
          jobs: data.data,
          totalCount: data.totalCount
        }));
      } else {
        setMessage('Jobs not found');
      }
    } catch (e) {
      console.error('Oops, something went wrong while getting jobs:', e);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchKeyWords() {
    try {
      const res = await axios.get('https://test22.aestar.com.ua/upw/key-words');
      const data = res.data;

      if (data.data.length) {
        setKeyWordsList(data.data);
        setApiData({
          ...apiData,
          keyWords: data.data
        })
      } else {
        console.log('Key Words list is empty');
      }
    } catch (e) {
      console.error('Oops, something went wrong while getting key words:', e);
    }
  }

  async function fetchJobById(jobId) {
    try {
      setIsLoading(true);
      const res = await axios.get(`https://test22.aestar.com.ua/upw/jobs/job?jobId=${jobId}`);

      if (res.data) {
        setApiData(prev => ({
          ...prev,
          jobs: [res.data.data],
          totalCount: null
        }));
      } else {
        setMessage('Job not found');
      }
    } catch (e) {
      console.error('Oops, something went wrong while getting the job:', e);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchKeyWords();
  }, []);

  useEffect(() => {
    const jobId = searchParams.get('jobId');
    if (jobId) {
      fetchJobById(jobId);
    } else {
      fetchJobs();
    }
  }, [currentPage, searchParams]);


  return (
    <section style={{ display: 'flex', flexDirection: 'column' }}>
      <Filter
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        count={apiData.itemsPerPage}
        offset={(currentPage - 1) * apiData.itemsPerPage}
        keyWordsList={keyWordsList}
        setKeyWordsList={setKeyWordsList}
        currentKeyWords={currentKeyWords}
        setCurrentKeyWords={setCurrentKeyWords}
        setFilters={setFilters}
      />
      {isLoading ? <Spinner mt={4} alignSelf={'center'} /> :
        apiData.jobs.length ? apiData.jobs.map(job => (
          <JobCard key={job.id} job={job} showKeyWords={keyWordsList.length} />
        )) :
          <Text fontSize='xl' align='center'>{message}</Text>
      }
      {
        apiData.totalCount &&
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(apiData.totalCount / apiData.itemsPerPage)}
          onPageChange={paginate}
        />
      }
    </section>
  )
}

export default Home